<!--  -->
<template>
  <div class='side-view' v-loading="loading">
    <div class="side-tabs round bj-col-white">
      <div class="side-list">
        <div class="user" v-if="userInfo && userInfo.xingming">
          <img :src="userInfo.img" alt="">
          <div>
            <span>{{userInfo.xingming}}</span>
            <div @click="handleQuit">退出登录</div>
          </div>
        </div>
        <div 
          v-for="(item, index) in routes" 
          :key="index" 
          :class="{'side-pitch': $route.meta.title == item.name}"
          @click="handleItem(index)">{{item.name}}</div>
      </div>
    </div>
    <div class="page-content">
      <keep-alive>
        <router-view v-if='$route.meta.keepAlive'></router-view>
      </keep-alive>
      <router-view v-if='!$route.meta.keepAlive'></router-view>
    </div>
  </div>
</template>

<script>
import Api from '@/api/home.js'

export default {
  components: {},
  data() {
    return {
      routes: [
        {
          name: '个人信息',
          path: '/User'
        },
        {
          name: '课程报名',
          path: '/Kecheng'
        },
        {
          name: '专业课程',
          path: '/Xuanke'
        },
        {
          name: '公需课程',
          path: '/Gongxu'
        },
        // {
        //   name: '我的委托书',
        //   path: '/Weituo'
        // },
        // {
        //   name: '药师考试',
        //   path: '/Xuankao'
        // },
        {
          name: '学分证明',
          path: '/Xuefen'
        },
        {
          name: '开具发票',
          path: '/Fapiao'
        },
        // {
        //   name: '课程体系',
        //   path: '/Tixi'
        // },
        {
          name: '操作手册',
          path: '/Action'
        },
        {
          name: '首页',
          path: '/Home'
        },
      ],
      loading: true,
      userInfo: {}
    };
  },
  computed: {},
  watch: {
    '$route.name' () {
      this.redirect()
    }
  },
  created() {
    document.getElementById("app").scrollTop = 0
    setTimeout(() => {
      this.loading = false
    }, 500)
    setTimeout(() => {
      this.redirect()
      let routes = this.routes
      let userInfo = localStorage.getItem("userInfo") || ''
      userInfo = userInfo ? JSON.parse(userInfo) : userInfo
      this.userInfo = userInfo
      // if (userInfo.kaohe == 3) {
        // routes[4] = {
        //   name: '学分证明',
        //   path: '/Xuefen'
        // }
      // } 
      // else {
      //   routes[4] = {
      //     name: '培训证明',
      //     path: '/Peixun'
      //   } 
      // }
      this.routes = []
      this.$nextTick(() => {
        this.routes = routes
        this.loading = false
      })
    }, 200)
  },
  mounted() {

  },
  //方法集合
  methods: {
    handleQuit () {
      Api.loginOut({})
      localStorage.setItem("userInfo", '')
      localStorage.setItem("token", '')
      localStorage.setItem("year", '')
      this.$store.commit('setToken', '')
      window.location.href = window.location.protocol + '//' + window.location.host
    },
    handleItem (idx) {
      let userInfo = localStorage.getItem("userInfo") || ''
      userInfo = userInfo ? JSON.parse(userInfo) : userInfo
      this.userInfo = userInfo
      if (this.routes[idx].path == '/Action') {
        let url = localStorage.getItem("czsc")
        window.open(url)
        return;
      }
      if (this.routes[idx].path == '/Xuefen' && this.userInfo.kaohe != 3 && this.userInfo.finish_gx == '0') {
        return;
      }
      this.$router.push({path: this.routes[idx].path})
    },
    redirect () {
      let arr = [
        '/Xuexi',
        '/Xuanke',
        '/Video',
        '/Xuankao',
        '/Kaoshi',
        '/Fapiao',
        '/Peixun',
      ]
      if (arr.indexOf(this.$route.path) != -1) {
        let userInfo = localStorage.getItem("userInfo")
        userInfo = JSON.parse(userInfo)
        // if (userInfo.attorney == '') {
        //   this.$router.replace({name: 'Weituo'})
        //   return;
        // }
        if (userInfo.xuanke != 1) {
          this.$router.replace({name: 'Gouke'})
          return;
        }
      }
    }
  },
}
</script>

<style lang='less' scoped>
.side-view {
  display: flex;
  justify-content: space-between;
  .side-tabs {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 40px 30px 20px;
    div {
      font-weight: 400;
    }
    .side-list {
      flex: 1;
      overflow-y: auto;
      > div {
        padding-left: 10px;
        margin-bottom: 30px;
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
      }
      .user {
        display: flex;
        cursor: default;
        padding-left: 0;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
        > div {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          span {
            font-size: 12px;
            text-align-last:justify;
            text-align:justify;
            text-justify:distribute-all-lines; 
          }
          div {
            font-size: 12px;
            color: @default;
            border: 1px solid @default;
            padding: 2px 8px;
            border-radius: 2px;
            cursor: pointer;
          }
          span, div {
            white-space: nowrap;
          }
        }
      }
    }
    .side-pitch {
      color: @default;
    }
  }
  .page-content {
    flex: 1;
    margin-left: 20px;
  }
}
</style>